html,
body {
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

:root {
  /* Text */
  --color-text-default: #111111;
  --color-text-secondary: #454545;
  --color-text-tertiary: #939393;
  --color-text-inverted: #ffffff;

  /* Background */
  --color-background-default: #ffffff;
  --color-background-float: #e2e2e2;
  --color-background-inline: #f1f1f1;
  --color-background-inverted: #111111;
  --color-background-multiply: #595959;

  /* Border */
  --color-border-default: #111111;
  --color-border-float: #cecece;
  --color-border-inline: #e2e2e2;
  --color-border-inverted: #ffffff;

  /* Success */
  --color-background-inline-success: #d2edcf;
  --color-background-success: #33832a;
  --color-border-success: #33832a;
  --color-text-success: #33832a;

  /* Info */
  --color-background-inline-info: #c8ece8;
  --color-background-info: #118595;
  --color-border-info: #118595;
  --color-text-info: #118595;

  /* Warning */
  --color-background-inline-warning: #eee5b5;
  --color-background-warning: #736101;
  --color-border-warning: #736101;
  --color-text-warning: #736101;

  /* Error */
  --color-background-inline-error: #fecaca;
  --color-background-error: #be1414;
  --color-border-error: #be1414;
  --color-text-error: #be1414;

  /* AI */
  --color-text-ai: #6318c2;
  --color-background-ai: #efe2ff;
  --color-border-ai: #6318c2;
}

/* TEXT */

.text-default {
  color: var(--color-text-default);
}

.text-secondary {
  color: var(--color-text-secondary);
}

.text-tertiary {
  color: var(--color-text-tertiary);
}

.text-inverted {
  color: var(--color-text-inverted);
}

.text-success {
  color: var(--color-text-success);
}

.text-info {
  color: var(--color-text-info);
}

.text-warning {
  color: var(--color-text-warning);
}

.text-error {
  color: var(--color-text-error);
}

.text-ai {
  color: var(--color-text-ai);
}

/* BACKGROUND */

.bg-default {
  background-color: var(--color-background-default);
}

.bg-float {
  background-color: var(--color-background-float);
}

.bg-multiply {
  background-color: var(--color-background-multiply);
}

.bg-inverted {
  background-color: var(--color-background-inverted);
}

.bg-success {
  background-color: var(--color-background-success);
}

.bg-info {
  background-color: var(--color-background-info);
}

.bg-inline-info {
  background-color: var(--color-background-inline-info);
}

.bg-warning {
  background-color: var(--color-background-warning);
}

.bg-error {
  background-color: var(--color-background-error);
}

.bg-inline {
  background-color: var(--color-background-inline);
}

.bg-inline-success {
  background-color: var(--color-background-inline-success);
}

.bg-inline-info {
  background-color: var(--color-background-inline-info);
}

.bg-inline-warning {
  background-color: var(--color-background-inline-warning);
}

.bg-inline-error {
  background-color: var(--color-background-inline-error);
}

.bg-ai {
  background-color: var(--color-background-ai);
}

/* BORDER */

.border-default {
  border-color: var(--color-border-default);
}

.border-float {
  border-color: var(--color-border-float);
}

.border-inline {
  border-color: var(--color-border-inline);
}

.border-inverted {
  border-color: var(--color-border-inverted);
}

.border-success {
  border-color: var(--color-border-success);
}

.border-info {
  border-color: var(--color-border-info);
}

.border-warning {
  border-color: var(--color-border-warning);
}

.border-error {
  border-color: var(--color-border-error);
}

.border-ai {
  border-color: var(--color-border-ai);
}
